.Login .container {
    max-width: 100%;
}

@media (max-width: 576px) {
    .Login .card {
        margin: 0 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .Login .card {
        margin: 0 20px;
    }
}
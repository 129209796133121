/* ซ่อนคอลัมน์บนหน้าจอขนาดเล็ก */
@media (max-width: 767.98px) {

    th:nth-child(3),
    td:nth-child(3) {
        /* ซ่อนคอลัมน์ Detail */
        display: none;
    }
}

/* แสดงคอลัมน์บนหน้าจอขนาดกลางขึ้นไป */
@media (min-width: 768px) {

    th:nth-child(3),
    td:nth-child(3) {
        /* แสดงคอลัมน์ Detail */
        display: table-cell;
    }
}

/* ปรับแต่ง Card */
.Package .card {
    margin-bottom: 20px;
    border-radius: 15px;
}

.Package .card-header {
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0;
}

.Package .card-body {
    padding: 1.5rem;
}

.Package .table th,
.Package .table td {
    vertical-align: middle;
    text-align: center;
}

.Package .table thead th {
    background-color: #4e73df;
    color: #fff;
}

.Package .table tbody tr:hover {
    background-color: #f8f9fc;
}

.Package .btn {
    border-radius: 15px;
}

.Package .btn-primary {
    background-color: #4e73df;
    border: none;
}

.Package .btn-danger {
    background-color: #e74a3b;
    border: none;
}
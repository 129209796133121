.main-box.no-header {
    padding-top: 20px;
}

.main-box {
    background: #FFFFFF;
    box-shadow: 1px 1px 2px 0 #CCCCCC;
    margin-bottom: 16px;
    border-radius: 3px;
}

.table a.table-link.danger {
    color: #e74c3c;
}

.label {
    border-radius: 3px;
    font-size: 0.875em;
    font-weight: 600;
}

.user-list tbody td {
    position: relative;
}

.user-list tbody td .badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
}

.user-link {
    margin-left: 10px;
}

a {
    color: #3498db;
    outline: none !important;
}

.user-list tbody td>img {
    max-width: 50px;
    margin-right: 15px;
}

.table thead th {
    text-transform: uppercase;
    font-size: 0.875em;
    border-bottom: 2px solid #e7ebee;
}

.table tbody tr td:first-child {
    font-size: 1.125em;
    font-weight: 300;
}

.table tbody tr td {
    font-size: 0.875em;
    vertical-align: middle;
    border-top: 1px solid #e7ebee;
    padding: 12px 8px;
}

a:hover {
    text-decoration: none;
}

.img-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.img-circle .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
}

.pagination {
    cursor: pointer;
}

.user-avatar {
    position: relative;
    display: inline-block;
}

.user-avatar .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
}

.action-buttons {
    display: inline-flex;
    gap: 0.5rem;
}

/* เพิ่มการตอบสนองต่อขนาดหน้าจอ */
@media (max-width: 767.98px) {
    .table-responsive {
        overflow-x: auto;
    }

    .d-flex {
        flex-direction: column;
    }

    .btn-sm {
        margin-top: 5px;
        width: 100%;
    }

    .user-avatar img {
        width: 40px;
        height: 40px;
    }

    .user-link {
        display: block;
        margin-left: 0;
    }

    .user-list tbody td {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem;
    }

    .user-list thead {
        display: none;
    }

    .user-list tbody tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        background: #f8f9fa;
        border-radius: 0.5rem;
        padding: 1rem;
    }

    .user-list tbody td>* {
        margin-bottom: 0.5rem;
    }

    .action-buttons {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}

.modal-label {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 8px;
}

.modal-text {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
}

.waiting-text {
    padding: '5rem'
}

.waiting-text::after {
    content: ' .';
    animation: dotAnimation 3s steps(4, end) infinite;
    display: inline-block;
}

@keyframes dotAnimation {

    0%,
    100% {
        content: ' ';
    }

    25% {
        content: ' .';
    }

    50% {
        content: ' ..';
    }

    75% {
        content: ' ...';
    }
}

/* สำหรับจัดการ spacing ของ radio buttons */
.form-check-inline {
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
  
  /* สำหรับปรับ label ของ radio buttons */
  .form-check-label {
    margin-left: 0.5rem;
    font-weight: 500;
  }
  